import { useEffect, useRef } from 'react';
import { NavigationType, useLocation, useNavigationType } from 'react-router-dom';

export const ScrollToHashElement = () => {
  const location = useLocation();
  const lastHash = useRef('');
  const navigationType = useNavigationType();

  useEffect(() => {
    if (location.hash && navigationType !== NavigationType.Pop) {
      lastHash.current = location.hash.slice(1);
    }

    if (lastHash.current && document.getElementById(lastHash.current)) {
      setTimeout(() => {
        document.getElementById(lastHash.current)?.scrollIntoView({ behavior: 'smooth' });
        lastHash.current = '';
      }, 100);
    }
  }, [location, navigationType]);

  return null;
};
