// src/components/modal/Modal.tsx
import { MouseEventHandler, PropsWithChildren, useEffect, useRef } from 'react';
import styles from './Modal.module.scss';

export interface ModalPropsI extends PropsWithChildren {
  text?: string;
  onHide?: () => void;
  modalClassName?: string; // Add this prop
}

export const Modal = ({ children, text, onHide, modalClassName }: ModalPropsI) => {
  const refModal = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const closeModal: MouseEventHandler<HTMLDivElement> = (e) => {
    if (refModal.current && !refModal.current.contains(e.target as Node)) {
      if (onHide) onHide();
    }
  };

  return (
    <div className={styles.overlay} onClick={closeModal}>
      <div className={`${styles.wrapper} ${modalClassName}`} ref={refModal}>
        {' '}
        {/* Apply the modalClassName here */}
        {!!text && <p className={styles.text}>{text}</p>}
        {children}
      </div>
    </div>
  );
};
