import { useLayoutEffect } from 'react';
import { NavigationType, useLocation, useNavigationType } from 'react-router-dom';

export const ScrollToTop = () => {
  const { pathname, search } = useLocation();
  const navigationType = useNavigationType();

  useLayoutEffect(() => {
    if (navigationType !== NavigationType.Pop) {
      document.documentElement.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant' as ScrollBehavior,
      });
    }
  }, [navigationType, pathname, search]);

  return null;
};
