import { Link, useLocation } from 'react-router-dom';

import { LogoIcon } from 'assets/LogoIcon';
import { Button } from 'components/button/Button';
import { RoutesE, navItems } from 'routes/RoutesE';

import styles from './Header.module.scss';
import { MobileMenu } from './components/MobileMenu';

export const Header = () => {
  const path = useLocation();

  const onLogoClick = () => {
    if (path.pathname === RoutesE.Landing) {
      window.scrollTo({ left: 0, top: 0 });
    }
  };

  return (
    <header className={styles.headerContainer}>
      <Link to="/" onClick={onLogoClick} className={styles.logoLink} aria-label="Home page">
        <LogoIcon className={styles.logo} />
      </Link>
      <div className={styles.desktopLinks}>
        {navItems.map((item) => (
          <Link
            key={item.name}
            className={styles.desktopLink}
            to={item.link}
            reloadDocument={path.pathname === RoutesE.Landing && item.link !== RoutesE.Frontends}
          >
            {item.name}
          </Link>
        ))}
        <Link key="tools" className={styles.desktopLink} to={RoutesE.Tools}>
          Tools
        </Link>
        <Link to={RoutesE.Frontends}>
          <Button>Frontends</Button>
        </Link>
      </div>
      <MobileMenu />
    </header>
  );
};
