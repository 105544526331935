import { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

import { PrivateRoutes } from './PrivateRoutes';
import { RoutesE } from './RoutesE';
import styles from './routes.module.scss';

const FrontendsPage = lazy(async () => ({ default: (await import('pages/frontends/FrontendsPage')).FrontendsPage }));
const PressPage = lazy(async () => ({ default: (await import('pages/press/PressPage')).PressPage }));
const ToolsPage = lazy(async () => ({ default: (await import('pages/tools/ToolsPage')).ToolsPage }));
const TermsPage = lazy(async () => ({ default: (await import('pages/terms/TermsPage')).TermsPage }));
const DisclaimerPage = lazy(async () => ({
  default: (await import('pages/disclaimer/DisclaimerPage')).DisclaimerPage,
}));
const PrivacyPage = lazy(async () => ({ default: (await import('pages/privacy/PrivacyPage')).PrivacyPage }));
const LandingPage = lazy(async () => ({ default: (await import('pages/landing/LandingPage')).LandingPage }));

export const AppRoutes = () => {
  return (
    <Suspense
      fallback={
        <div className={styles.spinnerContainer}>
          <span className="loading loading-spinner loading-lg text-primary" />
        </div>
      }
    >
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route path={RoutesE.Frontends} element={<FrontendsPage />} />
          <Route path={RoutesE.Press} element={<PressPage />} />
          <Route path={RoutesE.Tools} element={<ToolsPage />} />
          <Route path={RoutesE.Terms} element={<TermsPage />} />
          <Route path={RoutesE.Disclaimer} element={<DisclaimerPage />} />
          <Route path={RoutesE.Privacy} element={<PrivacyPage />} />
          <Route path="*" element={<LandingPage />} />
        </Route>
      </Routes>
    </Suspense>
  );
};
