import { ButtonHTMLAttributes } from 'react';

import { ArrowForwardIcon } from 'assets/icons/ArrowForwardIcon';

import styles from './Button.module.scss';

const ButtonVariantsE = {
  primary: styles.primary,
  secondary: styles.secondary,
  warning: styles.warning,
  transparent: styles.transparent,
};

interface ButtonPropsI extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: keyof typeof ButtonVariantsE;
  hasArrow?: boolean;
}

export const Button = ({ variant = 'primary', hasArrow = true, className, children, ...props }: ButtonPropsI) => {
  const classString = `${ButtonVariantsE[variant]} ${className || ''}`;

  return (
    <button className={classString} {...props}>
      {hasArrow && <ArrowForwardIcon className={styles.arrow} />}
      {children}
    </button>
  );
};
