import { BrowserRouter } from 'react-router-dom';

import { Footer } from 'components/footer/Footer';
import { Header } from 'components/header/Header';
import { StaticBackground } from 'components/static-background/StaticBackground';
import { ScrollToHashElement } from 'routes/ScrollToHashElement';
import { ScrollToTop } from 'routes/ScrollToTop';
import { AppRoutes } from 'routes/routes';

import styles from './App.module.scss';

export const App = () => {
  return (
    <div className={styles.root}>
      <BrowserRouter>
        <StaticBackground />
        <Header />
        <AppRoutes />
        <Footer />
        <ScrollToHashElement />
        <ScrollToTop />
      </BrowserRouter>
    </div>
  );
};
