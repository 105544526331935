import { SVGProps } from 'react';

export const LogoIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="129" height="30" viewBox="0 0 129 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_39_1191)">
        <path
          d="M25.7821 18.0126C24.5105 16.1062 22.3723 14.9606 20.081 14.9606H7.39815V7.56241H18.8161L15.9352 3.241C14.6545 1.32113 12.4961 0.166504 10.1868 0.166504H7.39815C3.31308 0.166504 0 3.47958 0 7.56466V14.9628C0 19.0479 3.31308 22.361 7.39815 22.361H28.6811L25.7844 18.0148L25.7821 18.0126Z"
          fill="black"
        />
        <path
          d="M40.0876 7.56494H18.8047L21.7014 11.9111C22.973 13.8175 25.1112 14.9631 27.4025 14.9631H40.0854V22.3612H28.6674L31.5483 26.6827C32.829 28.6048 34.9874 29.7594 37.299 29.7594H40.0876C44.1727 29.7594 47.4858 26.4463 47.4858 22.3612V14.9631C47.4858 10.878 44.1727 7.56494 40.0876 7.56494Z"
          fill="#6649DF"
        />
        <path
          d="M73.8734 26.8512C71.8432 28.8814 68.9848 29.7524 65.9171 29.7524H54.9785V0.247559H65.9171C68.9826 0.247559 71.8432 1.11859 73.8734 3.14875C77.3125 6.58787 76.9389 10.3578 76.9389 15.0011C76.9389 19.6444 77.3125 23.4121 73.8734 26.8512ZM68.9015 8.37045C68.2376 7.37563 67.245 6.71391 65.4197 6.71391H62.2281V23.2883H65.4197C67.2428 23.2883 68.2376 22.6243 68.9015 21.6318C69.4822 20.8035 69.6893 20.0157 69.6893 15.0011C69.6893 9.98647 69.4822 9.19872 68.9015 8.37045Z"
          fill="black"
        />
        <path
          d="M90.4892 29.9998C85.0604 29.9998 80.5859 27.0581 80.5859 20.9249C80.5859 17.2359 82.7826 15.4961 83.9013 14.5846C82.8659 13.7135 80.8763 11.9332 80.8763 8.74165C80.8763 3.14858 85.434 -0.00244141 90.4892 -0.00244141C95.5443 -0.00244141 100.102 3.14633 100.102 8.74165C100.102 11.9332 98.1124 13.7135 97.077 14.5846C98.1957 15.4961 100.392 17.2359 100.392 20.9249C100.392 27.0581 95.9179 29.9998 90.4892 29.9998ZM90.4892 17.6523C88.7899 17.6523 87.3809 19.0613 87.3809 20.7606C87.3809 22.4599 88.7899 23.8688 90.4892 23.8688C92.1885 23.8688 93.5974 22.4599 93.5974 20.7606C93.5974 19.0613 92.1885 17.6523 90.4892 17.6523ZM90.4892 6.13305C88.9136 6.13305 87.6712 7.37546 87.6712 8.95097C87.6712 10.5265 88.9136 11.7689 90.4892 11.7689C92.0647 11.7689 93.3071 10.5265 93.3071 8.95097C93.3071 7.37546 92.0647 6.13305 90.4892 6.13305Z"
          fill="black"
        />
        <path
          d="M119.991 29.7524L115.143 20.6775L110.295 29.7524H102.008L111.207 14.6275L102.588 0.247559H110.835L115.145 8.57752L119.455 0.247559H127.702L119.084 14.6275L128.283 29.7524H119.996H119.991Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_39_1191">
          <rect width="128.278" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
