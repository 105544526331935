import { useState } from 'react';
import { Link } from 'react-router-dom';

import { LogoIcon } from 'assets/LogoIcon';
import { CloseIcon } from 'assets/icons/CloseIcon';
import { DragHandleIcon } from 'assets/icons/DragHandleIcon';
import { Button } from 'components/button/Button';
import { RoutesE, navItems } from 'routes/RoutesE';

import styles from './MobileMenu.module.scss';

export const MobileMenu = () => {
  const [isMobileOpen, setIsMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setIsMobileOpen((v) => !v);
  };

  return (
    <div className="drawer drawer-end hidden w-fit sm:block">
      <input id="my-drawer-4" type="checkbox" className="drawer-toggle" checked={isMobileOpen} onChange={() => {}} />
      <div className="drawer-content">
        <label
          aria-label="open drawer"
          htmlFor="my-drawer-4"
          onClick={handleDrawerToggle}
          className={`drawer-button ${styles.burgerButton}`}
        >
          <DragHandleIcon />
        </label>
      </div>
      <div className="drawer-side">
        <label htmlFor="my-drawer-4" aria-label="close sidebar" className="drawer-overlay"></label>
        <div className="flex min-h-full w-60 flex-col bg-white xs:w-full">
          <div onClick={handleDrawerToggle}>
            <h6 className={`h6 ${styles.mobileHeader}`}>
              <LogoIcon className={styles.logo} />
              <button aria-label="close drawer" className={styles.burgerButton}>
                <CloseIcon />
              </button>
            </h6>
            <div className="py-2">
              {navItems.map((item) => (
                <Link key={item.name} to={item.link}>
                  <div className={styles.navMobileItem}>{item.name}</div>
                </Link>
              ))}
              <Link key="tools" to={RoutesE.Tools}>
                <div className={styles.navMobileItem}>Tools</div>
              </Link>
            </div>
            <div className={styles.frontendsButton}>
              <Link to={RoutesE.Frontends}>
                <Button>Frontends</Button>
              </Link>
            </div>
          </div>
          <Button variant="secondary" hasArrow={false} className={styles.closeButton} onClick={handleDrawerToggle}>
            Close
          </Button>
        </div>
      </div>
    </div>
  );
};
