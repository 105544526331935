import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';

import { theme } from 'styles/theme/theme';

import { App } from './App';
import { ToastContainerWrapper } from './ToastContainerWrapper';

import '@splidejs/react-splide/css';
import 'react-toastify/dist/ReactToastify.css';
import 'styles/index.scss';

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);

  root.render(
    <StrictMode>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </StyledEngineProvider>
      <ToastContainerWrapper />
    </StrictMode>
  );
}
