import { Link } from 'react-router-dom';

import { LogoIcon } from 'assets/LogoIcon';
import DiscordLogo from 'assets/social/discord.svg?react';
import GitbookLogo from 'assets/social/gitbook.svg?react';
import GithubLogo from 'assets/social/github.svg?react';
import MediumLogo from 'assets/social/medium.svg?react';
import TwitterLogo from 'assets/social/twitter.svg?react';
import { RoutesE } from 'routes/RoutesE';

import styles from './Footer.module.scss';

const SocialBlock = () => (
  <div className={styles.socialBlock}>
    <a
      href="https://github.com/D8-X"
      target="_blank"
      rel="noopener noreferrer"
      className={styles.footerSocialLogo}
      aria-label="D8X Github page"
    >
      <GithubLogo />
    </a>
    <a
      href="https://medium.com/@d8x.exchange"
      target="_blank"
      rel="noopener noreferrer"
      className={styles.footerSocialLogo}
      aria-label="D8X Medium page"
    >
      <MediumLogo />
    </a>
    <a
      href="https://twitter.com/d8x_exchange"
      target="_blank"
      rel="noopener noreferrer"
      className={styles.footerSocialLogo}
      aria-label="D8X Twitter page"
    >
      <TwitterLogo />
    </a>
    <a
      href="https://discord.gg/kUEZ5cvzKn"
      target="_blank"
      rel="noopener noreferrer"
      className={styles.footerSocialLogo}
      aria-label="D8X Discord page"
    >
      <DiscordLogo />
    </a>
    <a
      href="https://d8x.gitbook.io/d8x"
      target="_blank"
      rel="noopener noreferrer"
      className={styles.footerSocialLogo}
      aria-label="D8X Documentation page"
    >
      <GitbookLogo />
    </a>
  </div>
);

export const Footer = () => {
  return (
    <footer className={styles.rootBox}>
      <div className={styles.mobileOnly}>
        <SocialBlock />
      </div>
      <div className={styles.linksBlock}>
        <div className={styles.linksLine}>
          <Link to={RoutesE.Terms}>Terms of service</Link>
          <Link to={RoutesE.Disclaimer}>Disclaimer</Link>
          <Link to={RoutesE.Privacy}>Privacy policy</Link>
        </div>
        <div className={styles.desktopOnly}>
          <SocialBlock />
        </div>
      </div>
      <div className={styles.copyrightBlock}>
        <a
          href="https://d8x.exchange"
          className={styles.logoLink}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Home page"
        >
          <LogoIcon className={styles.footerLogo} />
        </a>

        <div className={styles.copyright}>2024 All rights reserved.</div>
      </div>
    </footer>
  );
};
